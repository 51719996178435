
import{defineComponent} from 'vue'

// Utils
import utilities from "../utilities";

export default defineComponent({
    name: "MyAccount",
    data(){
        return{
            token: '',
            validation: {} as any,
            
        }
    },
    async mounted(){
        this.validation = null;
        //check auth and get token
        let checkAuth = utilities.checkAuth('mi-cuenta') as any
        Promise.resolve(checkAuth).then((data) => {
            this.token = data as any
        })

        let valid = utilities.isValidated() as any
        Promise.resolve(valid).then((data) => {
            this.validation = data as any
        })
    },
    computed: {
        items() {
            return [
                {
                    icon: '/img/credit-card-solid.svg',
                    title: 'Pedidos',
                    text: 'Revisá el estado de tus pedidos',
                    link: '/pedidos'
                },
                {
                    icon: '/img/address-card-solid.svg',
                    title: 'Mis Datos',
                    text: 'Actualiza tus datos personales',
                    link: '/mis-datos'
                },
                {
                    icon: '/img/address-card-solid.svg',
                    title: 'Verificación',
                    text: this.validation.data ? 'Credencial valida hasta ' + this.validation.data.expiration_date : 'Validá tu credencial ' ,
                    link: '/validar-credencial',
                    check: this.validation.data ? true : false
                },
                // {
                //     icon: '/img/address-card-solid.svg',
                //     title: 'Mis Turnos',
                //     text: 'Revisá la confirmación de tus turnos',
                //     link: '/mis-turnos'
                // },
                {
                    icon: '/img/arrow-right-from-bracket-solid.svg',
                    title: 'Cerrar Sesión',
                    text: '¡Esperamos que vuelvas pronto!',
                    link: '/login?action=logout'
                }
            ]
        }
    }
})
